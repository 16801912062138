export const getFaintBgByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'rgba(249, 237, 128, 0.2)';
    case 'AIRNG':
      return '#F221210D';
    default:
      return '#0F61D605';
  }
};

export const getPryBtnColorByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'black';
    case 'AIRNG':
      return '#E20010';
    default:
      return '#0F61D6';
  }
};
