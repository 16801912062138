import Head from 'next/head';
import React from 'react';

type Props = {
  title: string;
  description: string;
};

const MetaHead = ({ title, description }: Props) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Head>
  );
};

export default MetaHead;
